import IncButton from 'components/inc-button/inc-button.component';
import { useForm, FormProvider } from 'react-hook-form';
import EnvSelectField from './env-select-field/env-select-field.component';
import CustomInput from 'components/custom-input/custom-input.component';
import { Box, InputLabel } from '@material-ui/core';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import Form from 'components/form/form.component';
import RHFDevtoolsWrapper from 'components/rhf-devtools-wrapper/rhf-devtools-wrapper.component';
import { useTranslation } from 'react-i18next';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import { useEnvForm } from 'components/env-config/env-drawer/env-form/env-form.hooks';
import { getEnv } from 'util/getEnv';
import Spinner from 'components/spinner/spinner.component';
import FormError from 'components/form-error/form-error.component';

const EnvForm = () => {
  const methods = useForm({
    defaultValues: {
      CLIENT_ALIAS: 'palmlaneqa',
      REACT_APP_RECAPTCHA_KEY: '',
      REACT_APP_SQUARE_URL: '',
      REACT_APP_MOBILE_API_URL: '',
    },
  });
  const { t } = useTranslation();

  const {
    environments,
    loading,
    onSubmit,
    resetEnv,
    setSelectedEnv,
    selectedEnv,
  } = useEnvForm();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      {loading && <Spinner />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <EnvSelectField
          environments={environments}
          setSelectedEnv={setSelectedEnv}
          selectedEnv={selectedEnv}
        />

        <CustomInput>
          <InputLabel htmlFor="apiUrl">
            {`${t('envConfig.form.baseUrl.label')} *`}
          </InputLabel>
          <TrimTextField
            id="apiUrl"
            fullWidth
            disabled={!!selectedEnv?.baseUrl}
            {...register('REACT_APP_MOBILE_API_URL', { required: true })}
          />
          {errors.REACT_APP_MOBILE_API_URL && (
            <FormError errorMessage={t('forms.errors.requiredField')} />
          )}
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="clientAlias">
            {t('envConfig.form.clientAlias.label')}
          </InputLabel>
          <TrimTextField
            id="clientAlias"
            fullWidth
            {...register('CLIENT_ALIAS')}
          />
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="recaptchaKey">
            {t('envConfig.form.recaptchaKey.label')}
          </InputLabel>
          <TrimTextField
            id="recaptchaKey"
            {...register('REACT_APP_RECAPTCHA_KEY')}
            fullWidth
          />
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="squareUrl">
            {t('envConfig.form.squareUrl.label')}
          </InputLabel>
          <TrimTextField
            id="squareUrl"
            {...register('REACT_APP_SQUARE_URL')}
            fullWidth
          />
        </CustomInput>

        <IncButton
          type="submit"
          className="inlineSubmitButton"
          fullWidth
          disabled={isSubmitting}
        >
          {t('envConfig.form.buttons.submit')}
        </IncButton>

        {!!sessionStorage.getItem('env') && (
          <Box mt={0.5}>
            <IncButtonDark
              fullWidth
              onClick={resetEnv}
              data-testid="DynamicEnvironmentForm.IncButtonDark.Reset"
            >
              {t('envConfig.form.buttons.reset')}
            </IncButtonDark>
          </Box>
        )}

        <Box textAlign="center" paddingTop={1}>
          <Box fontWeight="bold">
            {t('envConfig.form.currentEnv.label')} :{' '}
            {getEnv('REACT_APP_ENV_NAME')}
          </Box>
          {getEnv('REACT_APP_ENV_DESCRIPTION')}
        </Box>

        <RHFDevtoolsWrapper control={control} />
      </Form>
    </FormProvider>
  );
};

export default EnvForm;
